import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";

const VersionHistoryModal = ({ open, onClose, versionHistory, onReset }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="xs"
    fullScreen
    PaperProps={{
      sx: {
        position: "fixed",
        top: 0,
        right: 0,
        margin: 0,
        height: "100vh",
        width: "300px",
      },
    }}
  >
    <DialogTitle sx={{ m: 0, p: 2 }}>
      Version History
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{ p: 0, display: "flex", flexDirection: "column", height: "100%" }} dividers>
      <Typography sx={{ ml: 2, mt: 1 }}>{versionHistory.length} available versions</Typography>
      <List sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
        {versionHistory.map((version, index) => (
          <ListItemButton sx={{ border: "1px solid #EAEAEA", borderRadius: "6px", mb: 1.5 }} key={index}>
            <ListItemText
              primary={version.date}
              secondary={version.time}
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
          </ListItemButton>
        ))}
      </List>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 1, width: "90%", mx: "auto", textTransform: "none" }}
        onClick={onReset}
      >
        <HistoryIcon style={{ fontSize: "16px", marginRight: "8px" }} /> Reset to default
      </Button>
    </DialogContent>
  </Dialog>
);

export default VersionHistoryModal;
