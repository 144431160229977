import React, { useState } from "react";
import { Box, TextField, InputAdornment, MenuItem, List } from "@mui/material";
import { Search } from "@mui/icons-material";
import { deepPurple } from "@mui/material/colors";
import TemplateListItem from "./TemplateListItem";

const Templates = ({ templates, onEditTemplate }) => {
  const [sortBy, setSortBy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle sorting change
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  // Function to handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box sx={{ padding: "24px", width: "100%" }}>
      {/* Search Bar and Sort By */}
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
        {/* Search Bar */}
        <TextField
          placeholder="Find a template"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search htmlColor={deepPurple[500]} />
              </InputAdornment>
            ),
          }}
          sx={{ width: "60%" }}
        />

        {/* Sort By Dropdown */}
        <TextField select value={sortBy} onChange={handleSortChange} label="Sort By" sx={{ width: "30%" }}>
          <MenuItem value="recent">Most Recent</MenuItem>
          <MenuItem value="used">Most Used</MenuItem>
        </TextField>
      </Box>

      {/* Template List */}
      <List sx={{ width: "100%" }}>
        {templates
          .filter((template) => template.title.toLowerCase().includes(searchQuery.toLowerCase())) // Filter by search query
          .map((template) => (
            <TemplateListItem key={template.id} template={template} onEdit={onEditTemplate} />
          ))}
      </List>
    </Box>
  );
};

export default Templates;
