import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HistoryIcon from "@mui/icons-material/History";
import { deepPurple } from "@mui/material/colors";
import QuillEditor from "./QuillEditor";
import VersionHistoryModal from "./VersionHistoryModal";
import "./styles/style.css";

const TemplateEditor = () => {
  const navigate = useNavigate();

  const [innerHTML, setInnerHtml] = useState();
  const [openVersionHistory, setOpenVersionHistory] = useState(false);
  const editorRef = useRef();

  const handleOnBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1); // Only go back if there's a history to go back to
    } else {
      navigate("/settings/templates/email"); // Navigate to a fallback route
    }
  };

  const handleInsertVariable = (variable) => {
    const quill = editorRef.current;
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        quill.insertText(range.index, variable);
      }
    }
  };

  const handleOpenVersionHistory = () => setOpenVersionHistory(true);
  const handleCloseVersionHistory = () => setOpenVersionHistory(false);
  const handleResetVersion = () => {
    console.log("Resetting to default version");
  };

  // Test data
  const testVariables = ["{{ testVar1 }}", "{{ testVar2 }}", "{{ testVar3 }}"];
  const testVersionHistory = [
    { date: "September 28, 2024", time: "11:50 AM" },
    { date: "September 25, 2024", time: "4:25 PM" },
    { date: "September 24, 2024", time: "7:11 PM" },
  ];

  return (
    <>
      <Button variant="text" sx={{ textTransform: "none", color: "black" }} onClick={handleOnBack}>
        <ChevronLeftIcon htmlColor={deepPurple[500]} />
        Back to files
      </Button>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "24px 0",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5" fontWeight="bold">
            Bank statements
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
            <Typography variant="body2" sx={{ marginRight: "8px" }}>
              Version
            </Typography>
            <Chip
              label="Sept 25, 2024 | 11:50 AM"
              size="small"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                fontWeight: "500",
                fontSize: "12px",
              }}
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          startIcon={<HistoryIcon />}
          sx={{
            backgroundColor: "#6200ea",
            color: "white",
            textTransform: "none",
            padding: "8px 16px",
          }}
          onClick={handleOpenVersionHistory}
        >
          Version History
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ padding: 4 }}>
        {/* Left Container: Email Editor */}
        <Grid item xs={12} md={8}>
          <Box id="quill-editor" sx={{ minHeight: "50vh", maxHeight: "50vh" }}>
            <QuillEditor ref={editorRef} setInnerHtml={setInnerHtml} />
          </Box>
          <Grid>
            <Box>Test HTML Output: {innerHTML}</Box>
          </Grid>
        </Grid>
        {/* <Divider variant="fullWidth" orientation="vertical" /> */}
        {/* Right Container: Variables */}
        <Grid item xs={12} md={3} sx={{ height: "100%" }}>
          <Typography variant="h4">Test Variables</Typography>
          {testVariables.map((variable) => (
            <Button
              key={variable}
              variant="outlined"
              sx={{ margin: "8px 0" }}
              onClick={() => handleInsertVariable(variable)}
            >
              {variable}
            </Button>
          ))}
        </Grid>
      </Grid>
      <VersionHistoryModal
        open={openVersionHistory}
        onClose={handleCloseVersionHistory}
        versionHistory={testVersionHistory}
        onReset={handleResetVersion}
      />
    </>
  );
};

export default TemplateEditor;
