import React, { forwardRef, useEffect, useRef } from "react";
import { Quill } from "react-quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import "./styles/quill.snow.css";
import "./styles/style.css";

let Embed = Quill.import("blots/embed");

class TemplateMarker extends Embed {
  static create(value) {
    let node = super.create(value);

    // Set the CSS class
    node.setAttribute("class", "dynamic-variable");

    node.setAttribute("data-marker", value.marker);

    node.setAttribute("data-title", value.title);

    node.innerHTML = value.title;

    return node;
  }

  static value(node) {
    return {
      marker: node.getAttribute("data-marker"),
      title: node.getAttribute("data-title"),
    };
  }
}

TemplateMarker.blotName = "TemplateMarker";
TemplateMarker.tagName = "span";

Quill.register({
  "formats/TemplateMarker": TemplateMarker,
});

// Editor is an uncontrolled React component
const QuillEditor = forwardRef(
  (
    {
      // readOnly,
      defaultValue,
      setInnerHtml,
      // onTextChange,
      // onSelectionChange,
    },
    ref,
  ) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    // const onTextChangeRef = useRef(onTextChange);
    // const onSelectionChangeRef = useRef(onSelectionChange);

    // useLayoutEffect(() => {
    //   // onTextChangeRef.current = onTextChange;
    //   // onSelectionChangeRef.current = onSelectionChange;
    // });

    // useEffect(() => {
    //   ref.current?.enable(!readOnly);
    // }, [ref, readOnly]);

    useEffect(() => {
      const container = document.getElementById("quill-editor");
      containerRef.current = container;

      const quill = new Quill(container, {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            ["link", "image", "video", "formula"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
          ],
        },
        theme: "snow",
      });
      quill.root.setAttribute("spellcheck", false);

      ref.current = quill;
      // quill.clipboard.dangerouslyPasteHTML(htmlContent); // FOr set default html 

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
        setInnerHtml(quill.root.innerHTML);
      }

      let isHandlingChange = false; // Flag to prevent recursive text change handling

      quill.on(Quill.events.TEXT_CHANGE, () => {
        if (isHandlingChange) return;

        isHandlingChange = true; // Set the flag to prevent recursion

        const delta = quill.getContents();
        const ops = delta.ops;

        const markerRegex = /\{{(.+?)\}}/g;

        let currentIndex = 0;

        ops.forEach((op) => {
          // Only process plain text, skip existing markers (embedded objects)
          if (typeof op.insert === "string") {
            let match;
            while ((match = markerRegex.exec(op.insert)) !== null) {
              const markerName = match[0];
              const startIndex = currentIndex + match.index;

              // Replace the plain marker text with a TemplateMarker
              quill.deleteText(startIndex, markerName.length, Quill.sources.SILENT); // Use SILENT to avoid triggering TEXT_CHANGE again
              quill.insertEmbed(
                startIndex,
                "TemplateMarker",
                {
                  marker: markerName,
                  title: markerName,
                },
                Quill.sources.SILENT,
              ); // Insert the TemplateMarker silently

              quill.insertText(startIndex + 1, " ", Quill.sources.SILENT);
              quill.setSelection(startIndex + 2, Quill.sources.SILENT);
            }
          }

          currentIndex += op.insert.length || 1; // Update current index position
        });

        isHandlingChange = false; // Reset the flag after handling

        let qdc = new QuillDeltaToHtmlConverter(delta.ops, window.opts_ || {});

        qdc.renderCustomWith(function (customOp, contextOp) {
          if (customOp.insert.type === "TemplateMarker") {
            let val = customOp.insert.value;
            return val.marker;
          }
        });

        // Convert the Delta JSON to HTML
        let html = qdc.convert();

        setInnerHtml(html);
      });

      // quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      //   onSelectionChangeRef.current?.(...args);
      // });

      // Moving toolbar to another container
      // const toolbar = document.querySelector(".ql-toolbar");
      // const toolbarContainer = document.getElementById('test-toolbar');
      // toolbarContainer.appendChild(toolbar);

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, [ref]);

    return <div ref={containerRef}></div>;
  },
);

QuillEditor.displayName = "QuillEditor";

export default QuillEditor;
