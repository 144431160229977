import { Box, Button, ListItem, Typography } from "@mui/material";

const TemplateListItem = ({ template, onEdit }) => (
  <ListItem
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px 0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    }}
  >
    {/* Template Information */}
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* Image or Placeholder */}
      <img src={""} alt="Template" style={{ width: "40px", height: "40px", marginRight: "16px" }} />

      {/* Template Details */}
      <Box>
        <Typography variant="subtitle1" fontWeight="bold">
          {template.title}
          {template.status === "Used" && (
            <Typography
              component="span"
              sx={{
                backgroundColor: "green",
                color: "white",
                borderRadius: "42px",
                padding: "2px 6px",
                marginLeft: "8px",
                fontSize: "12px",
              }}
            >
              {template.status}
            </Typography>
          )}
          {template.status === "Unused" && (
            <Typography
              component="span"
              sx={{
                backgroundColor: "transparent",
                color: "black",
                border: "1px solid gray",
                borderRadius: "42px",
                padding: "2px 6px",
                marginLeft: "8px",
                fontSize: "12px",
              }}
            >
              {template.status}
            </Typography>
          )}
        </Typography>
        <Typography variant="body2">{template?.type} Template</Typography>
        <Typography variant="body2">{template?.versions} versions</Typography>
      </Box>
    </Box>

    {/* Edit Button */}
    <Button
      variant="contained"
      sx={{ backgroundColor: "deeppurple", color: "white" }}
      onClick={() => onEdit(template.id)}
    >
      Edit Template
    </Button>
  </ListItem>
);

export default TemplateListItem;
