import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import Lenders from "./lenders/Lender";
import UserDetails from "./profile/UserDetails";
import UserRolesList from "./UserRolesList";
import RolePermission from "./RolePermission";
import Templates from "./Templates";
import TemplateEditor from "./Templates/TemplateEditor";
import BillingWrapper from "../../containers/BillingWrapper";
import { userSelector } from "../../store/slices/userSlice";
import { userType } from "../../constants";
import SettingsSidebar from "../../layouts/SettingsSidebar";

const templateData = [
  {
    id: 1,
    title: "Bank statements",
    status: "Used",
    type: "Email",
    versions: 1,
    imageUrl: "/path-to-image.png", // Image URL or placeholder
  },
  {
    id: 2,
    title: "Application Lodged",
    status: "Unused",
    type: "Email",
    versions: 0,
    imageUrl: "/path-to-image.png", // Image URL or placeholder
  },
  {
    id: 3,
    title: "Privacy statement",
    status: "Unused",
    type: "Email",
    versions: 0,
    imageUrl: "/path-to-image.png", // Image URL or placeholder
  },
];

export default function UserProfile() {
  const templatePathRegex = /^\/settings\/templates\/(?!sms|email)\w+$/;
  const { path } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const userState = useSelector(userSelector);

  const [hideSidebar, setHideSidebar] = useState(false);

  useEffect(() => {
    if (templatePathRegex.test(location.pathname)) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
  }, [location.pathname]);

  const handleEditTemplate = (id) => {
    // Handle navigation or editing logic here
    setHideSidebar(true);
    navigate(`/settings/templates/${id}`);
  };

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        spacing={3}
        // style={{ padding: "30px 50px 100px" }}
        // justifyContent="center"
        // alignItems="center"
        direction="row"
      >
        {!hideSidebar && (
          <Grid
            container
            item
            xs={12}
            sm={3}
            md={2}
            lg={2}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            <SettingsSidebar {...{ path }} />
          </Grid>
        )}
        {path === "general" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            <UserDetails />
          </Grid>
        )}

        {path === "lenders" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            // sm={10}
            // md={10}
            // xl={8}
            // height="100vh"
            justifyContent="start"
            alignItems="start"
            direction="column"
            // textAlign="center"
            // style={{ marginTop: "64px" }}
          >
            <Lenders />
          </Grid>
        )}
        {[userType.owner, userType.master].includes(userState?.userType) && path === "user" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="column"
          >
            <BillingWrapper title={"User"}>
              <UserRolesList />
            </BillingWrapper>
          </Grid>
        )}
        {[userType.owner, userType.master].includes(userState?.userType) && path === "rolepermissions" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="column"
          >
            <BillingWrapper title={"Role & Permissions"}>
              <RolePermission />
            </BillingWrapper>
          </Grid>
        )}
        {[userType.owner, userType.master].includes(userState?.userType) &&
          path === "templates" &&
          location.pathname === "/settings/templates/email" && (
            <Grid
              container
              item
              xs={12}
              sm={9}
              md={9}
              lg={8}
              width="100%"
              justifyContent="start"
              alignItems="start"
              direction="column"
            >
              <BillingWrapper title={"Email Templates"}>
                <Templates templates={templateData} onEditTemplate={handleEditTemplate} />
              </BillingWrapper>
            </Grid>
          )}
        {[userType.owner, userType.master].includes(userState?.userType) &&
          path === "templates" &&
          location.pathname === "/settings/templates/sms" && (
            <Grid
              container
              item
              xs={12}
              sm={9}
              md={9}
              lg={8}
              width="100%"
              justifyContent="start"
              alignItems="start"
              direction="column"
            >
              <BillingWrapper title={"SMS Templates"}>
                <Templates templates={templateData} onEditTemplate={handleEditTemplate} />
              </BillingWrapper>
            </Grid>
          )}
        {[userType.owner, userType.master].includes(userState?.userType) &&
          path === "templates" &&
          templatePathRegex.test(location.pathname) && (
            <Grid
              container
              item
              xs={12}
              sm={9}
              md={12}
              lg={12}
              width="100%"
              justifyContent="start"
              alignItems="start"
              direction="column"
            >
              <TemplateEditor />
            </Grid>
          )}
      </Grid>
    </>
  );
}
